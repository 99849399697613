const rootAPI = process.env.REACT_APP_ROOT_API || 'http://localhost:8000/api/v1';
const cubeAPI = process.env.REACT_APP_CUBE_API || 'https://cube.staging.xiot.com.au/cubejs-api/v1'
// eslint-disable-next-line
export default {
    cubeAPI: cubeAPI,
    me: `${rootAPI}/users/me`,
    getCubeToken: `${rootAPI}/cube/token`,
    signIn: `${rootAPI}/auth/login`,
    forgotPassword: `${rootAPI}/auth/forgot-password`,
    resetPassword: `${rootAPI}/auth/reset-password`,
    getLocations: `${rootAPI}/locations/`,
    getLocationDetails: `${rootAPI}/locations/:location_id`,
    locationStartNew: `${rootAPI}/locations/:location_id/start-new`,
    locationNewBatch: `${rootAPI}/locations/:location_id/new-batch`,
    locationMoveBatch: `${rootAPI}/locations/:location_id/move-batch`,
    getPens: `${rootAPI}/pens/`,
    getPenDetails: `${rootAPI}/pens/:pen_id`,
    getAnimalMovements: `${rootAPI}/animal-movements/`,
    getAnimals: `${rootAPI}/animals/`,
    getAnimalDetails: `${rootAPI}/animals/:animal_id`,
    getAnimalDetailByTagId: `${rootAPI}/animals/get-by-tag/:tagId`,
    getAnimalsObservationReport: `${rootAPI}/animals/observation-report`,
    uploadObservationRecords: `${rootAPI}/observation-records/upload`,
    getAnimalAlerts: `${rootAPI}/alerts/`,
    getAnimalAlertsV2: `${rootAPI}/alerts-list/`,
    getLitterSummary: `${rootAPI}/litter-summary`,
    getBreedingEvent: `${rootAPI}/breeding-event`,
    getBreedingEventRecords: `${rootAPI}/animals/:animalId/breeding-event-records`,
    imageUrl: `${rootAPI.replace('/api/202312', '')}`,
    getWeatherForecast: `${rootAPI}/weather-forecast`,
    getFarms: `${rootAPI}/farms`,
    getTagsStats: `${rootAPI}/stats/tags`,
    getLocationTagsStats: `${rootAPI}/stats/room-tags`,
    getEventLog: `${rootAPI}/event-logs`,
    getDailyManagementList: `${rootAPI}/management-tasks`,
    getDailyMonitorList: `${rootAPI}/monitor-tasks`,
    addDailyTasks: `${rootAPI}/daily-tasks`,
}